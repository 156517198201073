/**********************
Global Styles
**********************/
@import url("https://use.typekit.net/ryr0ixb.css");
*,
*:after,
*:before {
  box-sizing: border-box; }

/** Fixing bootstrap columns for safari **/
.row:after,
.row:before {
  display: inline-block !important; }

body {
  color: #222F3C;
  font-size: 17px;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: sofia-pro, sans-serif;
  text-rendering: geometricPrecision;
  overflow-x: hidden; }

a,
a:focus,
a:hover,
a:visited {
  text-decoration: none;
  outline: 0; }

section {
  padding: 120px 0; }

button {
  border: 0;
  background-color: transparent; }
  button:hover {
    cursor: pointer; }
  button:focus {
    outline: 0; }

.container {
  max-width: 1140px; }

.section-title {
  font-size: 40px;
  line-height: 1.2;
  margin-bottom: 12px;
  text-transform: uppercase;
  text-rendering: geometricPrecision;
  font-family: brandon-grotesque, sans-serif;
  font-weight: 900; }

.sub-heading {
  font-family: sofia-pro, sans-serif;
  font-weight: 700;
  font-size: 22px;
  line-height: 1.4;
  color: #222F3C;
  margin-bottom: 25px; }

strike {
  color: #797979; }

.img-container {
  text-align: center; }

.sale-text {
  font-weight: 700;
  font-size: 16px;
  margin: 20px 0;
  color: #2ed2ec; }

.section-header {
  margin-bottom: 50px; }

@media (max-width: 600px) {
  .section-header {
    margin-bottom: 30px; } }

.secondary {
  background-color: #222F3C !important;
  border-color: #222F3C !important; }

.check {
  height: 20px !important;
  margin-right: 4px !important;
  margin-bottom: 0 !important; }

.text-black {
  color: #222F3C !important; }

.border-white {
  border: 2px solid #fff;
  border-radius: 5px; }

.border-black {
  border: 2px solid #000;
  border-radius: 5px; }

.link-color {
  color: #E74C3C;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  font-weight: 700; }
  .link-color:hover {
    color: #E74C3C;
    opacity: .8; }

/* Buttons */
.btn-prime,
.btn-prime:link,
.btn-prime:visited {
  padding: 10px 20px;
  letter-spacing: 2px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  display: inline-block;
  font-size: 17px;
  text-transform: uppercase;
  text-align: center;
  vertical-align: middle;
  font-weight: 700;
  box-shadow: none !important;
  outline: 0; }
  .btn-prime span,
  .btn-prime:link span,
  .btn-prime:visited span {
    text-transform: initial; }

.btn-prime:hover {
  opacity: 0.9; }

.btn-prime-dark,
.btn-prime-dark:focus,
.btn-prime-dark:hover {
  border: 2px solid #E74C3C;
  background-color: #E74C3C;
  color: #fff; }

.btn-prime-border,
.btn-prime-border:focus,
.btn-prime-border:hover {
  background-color: transparent;
  border: 2px solid #fff;
  color: #fff; }

.flex-container {
  -webkit-box-align: center;
  align-items: center; }

/** MEDIA QUERIES **/
@media only screen and (max-width: 1199px) {
  .container {
    max-width: 1060px; } }

@media only screen and (max-width: 991px) {
  section {
    padding: 50px 0; }
  .navbar-dark .navbar-nav .nav-link,
  .navbar-dark .navbar-nav .nav-link:active,
  .navbar-dark .navbar-nav .nav-link:focus,
  .navbar-dark .navbar-nav .nav-link:hover,
  .navbar-dark .navbar-nav .nav-link:visited {
    font-size: 12px; }
  .container {
    max-width: 890px; }
  .section-title {
    font-size: 38px; } }

@media only screen and (max-width: 767px) {
  #main-nav {
    display: none !important; }
  .container {
    max-width: 100%; }
  .mobile-menu img {
    height: 34px; }
  .mobile-menu input + label {
    right: 15px; } }

@media only screen and (max-width: 600px) {
  .section-title {
    font-size: 30px; }
  p {
    font-size: 16px; } }

@media only screen and (max-width: 475px) {
  .mobile-menu .navbar-brand {
    left: 15px; } }

@media only screen and (max-width: 450px) {
  section {
    padding: 50px 0; }
  .sub-heading {
    font-size: 18px;
    line-height: 24px; }
  .btn-prime,
  .btn-prime:link,
  .btn-prime:visited {
    font-size: 14px; }
  .sale-text {
    font-size: 22px; } }

@media only screen and (max-width: 375px) {
  .section-title {
    font-size: 22px !important; } }

.slick-dots li button:before {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-image: url("../img/dot-border.png");
  background-repeat: no-repeat;
  content: "";
  opacity: 1; }

.slick-dots li.slick-active button:before {
  background-image: url("../img/dot-full.png");
  background-repeat: no-repeat; }

.slick-slide img {
  margin: 0 auto; }

.slick-list {
  margin: 0 -10px; }

.slick-slide {
  margin: 0 10px; }

.slick-track {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  cursor: pointer; }

/**********************
Navigation
**********************/
nav {
  padding: 0 !important; }

nav .nav-item {
  padding: 0; }

.navbar-dark .navbar-nav .nav-link {
  color: #fff;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  padding: 10px 15px !important; }

.navbar-dark .navbar-nav .nav-item,
.navbar-dark .navbar-nav .nav-item:active,
.navbar-dark .navbar-nav .nav-item:focus,
.navbar-dark .navbar-nav .nav-item:hover,
.navbar-dark .navbar-nav .nav-item:visited {
  color: #fff;
  font-size: 13px;
  outline: 0;
  letter-spacing: 0.05em;
  font-weight: 700;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  text-transform: uppercase; }

.navbar-dark .navbar-nav .nav-link:hover {
  background-color: transparent;
  opacity: 0.6; }

.navbar-brand:hover,
.navbar-dark .navbar-brand {
  font-weight: 700;
  color: #E74C3C; }
  .navbar-brand:hover img,
  .navbar-dark .navbar-brand img {
    max-width: 200px; }

.navbar-nav .dropdown-menu {
  left: auto;
  right: 0;
  border: 0;
  border-radius: 0;
  margin-top: 0; }

.dropdown-item {
  background-color: #fff; }

.dropdown-menu.show {
  background-color: #fff;
  padding: 0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }

.dropdown-item.active,
.dropdown-item:active {
  background-color: #f8f8f8;
  color: #212529; }

.dropdown-item:focus,
.dropdown-item:hover {
  color: #fff;
  background-color: #E74C3C; }

.cta-btn {
  border-radius: 0;
  line-height: inherit;
  border: 2px solid #fff;
  margin-left: 26px; }
  .cta-btn:hover {
    color: white !important;
    background: #E74C3C; }
  .cta-btn .nav-link {
    color: #fff !important; }

#main-nav {
  background-color: transparent;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }

/* Mobile Menu */
header {
  background-color: #fff;
  position: relative; }

@media screen and (min-width: 992px) {
  .mobile-menu {
    display: none; } }

@media screen and (max-width: 991px) {
  #main-nav {
    display: none; }
  .buy-now {
    background: #E74C3C !important; }
  .mobile-menu input ~ nav > ul > li > a.buy-now {
    max-width: 300px;
    margin: 0 auto; }
  .transparent-header {
    box-shadow: 2px 2px 5px 0 rgba(173, 173, 173, 0.3); }
  .mobile-nav .btn-prime.btn-prime-dark {
    color: #fff;
    font-size: 26px;
    width: 100%; }
  input#burger {
    display: none; }
  .mobile-menu .navbar-brand {
    font-weight: 700;
    color: #E74C3C; }
  .mobile-menu input:not(:checked) .mobile-links {
    display: none; }
  .mobile-menu input + label {
    position: fixed;
    top: 20px;
    right: 20px;
    height: 20px;
    width: 34px;
    z-index: 5;
    padding: 0;
    margin-bottom: 0; }
  .mobile-menu input + label span {
    position: absolute;
    width: 100%;
    height: 2px;
    top: 60%;
    margin-top: -1px;
    left: 0;
    display: block;
    background: #fff;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }
  .mobile-menu input + label span:first-child {
    top: 4px; }
  .mobile-menu input + label span:last-child {
    top: 20px; }
  .mobile-menu label:hover {
    cursor: pointer; }
  .mobile-menu input:checked + label span {
    opacity: 0;
    top: 50%; }
  .mobile-menu input:checked + label span:first-child {
    opacity: 1;
    -webkit-transform: rotate(405deg);
    transform: rotate(405deg); }
  .mobile-menu input:checked + label span:last-child {
    opacity: 1;
    -webkit-transform: rotate(-405deg);
    transform: rotate(-405deg); }
  .mobile-menu input ~ nav {
    background-color: #222F3C;
    box-shadow: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    z-index: 3;
    overflow: hidden; }
  .mobile-menu nav {
    position: relative; }
  .mobile-menu input ~ nav > ul {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    padding: 0;
    margin-bottom: 0;
    width: 80%; }
  .mobile-menu input ~ nav > ul > li {
    display: none; }
  .mobile-menu input ~ nav > ul > li > a {
    color: #fff;
    font-weight: 700;
    display: block;
    letter-spacing: 0.05em;
    padding: 15px;
    font-size: 22px;
    text-transform: uppercase;
    margin: 10px 0; }
  .mobile-menu input:checked ~ nav {
    height: 100%;
    -webkit-transition-delay: 0s;
    transition-delay: 0s; }
  .mobile-links {
    list-style: none; }
  .mobile-menu input:checked ~ nav > ul > li {
    display: initial; }
  .mobile-menu .navbar-brand {
    position: absolute;
    top: 0;
    left: 15px;
    z-index: 3000;
    height: 100%;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    max-width: 170px; } }

@media screen and (max-width: 575px) {
  .mobile-menu input ~ nav > ul > li > a,
  .mobile-nav .btn-prime.btn-prime-dark {
    font-size: 17px; } }

@media screen and (max-width: 450px) {
  .mobile-menu .navbar-brand img {
    height: 32px; } }

.transparent-header {
  padding: 30px 0; }

/**********************
Hero
**********************/
.hero {
  background: none;
  color: #fff;
  position: relative; }
  .hero .big-img {
    position: absolute;
    top: 80px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%); }
  .hero .section-title {
    font-size: 44px; }
  .hero .grid-row {
    display: grid;
    grid-template-columns: repeat(2, 1fr); }
  .hero .img-main-container {
    min-height: 1000px;
    background-image: url("../img/hero-img.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center; }
  .hero .text-area {
    background-color: #E74C3C;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    padding: 0; }
    .hero .text-area .grid-row {
      grid-template-columns: repeat(4, 1fr);
      -webkit-box-align: center;
      align-items: center;
      margin-top: 40px; }
    .hero .text-area .content {
      max-width: 750px;
      position: relative;
      z-index: 1; }
    .hero .text-area .sub-heading {
      color: #fff;
      font-size: 26px; }
  .hero .cta-container {
    margin-top: 40px; }

@media screen and (max-width: 1700px) and (min-width: 1200px) {
  .hero .section-title {
    font-size: 40px; }
  .hero .text-area .sub-heading {
    font-size: 20px; } }

@media (max-width: 1800px) {
  .hero {
    position: relative;
    background-image: url("../img/hero-img.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    height: 800px;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    text-align: center; }
    .hero::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #000;
      opacity: 0.3; }
  .hero .text-area {
    background-color: transparent;
    padding: 0 15px; }
  .hero .grid-row {
    grid-template-columns: 1fr; }
  .hero .big-img,
  .img-main-container {
    display: none; }
  .hero .text-area .content {
    margin: 0 auto; } }

@media (min-width: 2000px) {
  .hero .text-area {
    padding: 0 0 0 100px; } }

@media (max-width: 991px) {
  .hero {
    margin-top: 60px; }
  .hero .section-title {
    font-size: 35px; }
  .hero .text-area .sub-heading {
    font-size: 23px; } }

@media (max-width: 600px) {
  .hero {
    height: auto;
    padding: 50px 0; }
  .hero .text-area .sub-heading {
    font-size: 18px; }
  .hero .text-area .grid-row {
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
    -webkit-box-pack: center;
    justify-content: center;
    margin-top: 40px; }
    .hero .text-area .grid-row img {
      margin: 0 auto; } }

.perks {
  text-align: center;
  background-color: #F1F3F5;
  padding: 80px 0; }
  .perks .grid-row {
    display: grid;
    gap: 30px;
    grid-template-columns: repeat(4, 1fr); }
  .perks .icon-container img {
    margin-bottom: 20px; }
  .perks .icon-container .icon-title {
    text-transform: uppercase;
    font-family: brandon-grotesque, sans-serif;
    font-weight: 900;
    margin-bottom: 10px; }
    .perks .icon-container .icon-title span {
      display: block; }
  .perks .icon-container .icon-text {
    margin-bottom: 0; }

@media only screen and (max-width: 991px) {
  .perks .grid-row {
    gap: 50px;
    grid-template-columns: repeat(2, 1fr); } }

@media only screen and (max-width: 600px) {
  .perks .grid-row {
    grid-template-columns: 1fr; } }

/******************************
Video
******************************/
.video {
  text-align: center;
  background-color: #F1F3F5; }
  .video .main-video .video-container {
    position: relative;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center; }
  .video .main-video .img-container {
    position: relative; }
    .video .main-video .img-container::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #000;
      opacity: 0; }
  .video .main-video .play-btn {
    position: absolute;
    max-width: 80px;
    z-index: 1; }
  .video .text {
    margin: 40px 0; }

@media (max-width: 700px) {
  .video .cta-container .btn-prime {
    display: block; }
    .video .cta-container .btn-prime.secondary {
      margin-top: 10px; } }

/**********************
included
**********************/
.included {
  position: relative;
  background-image: url("../img/included.jpg");
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center center; }
  .included .section-header {
    text-align: center; }
  .included::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.3; }
  .included .container {
    position: relative;
    z-index: 1; }
  .included .section-title {
    color: #fff; }
  .included .grid-row {
    display: grid;
    grid-template-columns: 1fr;
    background-color: #fff;
    row-gap: 10px;
    padding: 10px 10px 0;
    -webkit-box-align: center;
    align-items: center; }
    .included .grid-row .cta-container {
      background-color: #fff;
      padding: 50px 15px;
      text-align: center; }
    .included .grid-row .item {
      background-color: #fff;
      display: grid;
      grid-template-columns: 27% 73%;
      -webkit-column-gap: 30px;
      -moz-column-gap: 30px;
      column-gap: 30px;
      row-gap: 10px; }
      .included .grid-row .item .content {
        height: 100%;
        display: -webkit-box;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        -webkit-box-pack: center;
        justify-content: center;
        border-bottom: 1px solid #c7ced3;
        margin-right: 30px; }
        .included .grid-row .item .content .title {
          width: 100%;
          font-family: brandon-grotesque, sans-serif;
          font-weight: 900;
          font-size: 22px; }
      .included .grid-row .item .img-block img {
        width: 100%; }

@media only screen and (max-width: 991px) {
  .included .grid-row {
    gap: 50px;
    padding: 0;
    background-color: transparent;
    text-align: center; }
  .included .grid-row .item .img-block img {
    width: auto;
    padding-top: 30px; }
  .included .grid-row .item .content {
    margin-right: 0;
    padding: 30px 20px;
    text-align: center; }
  .included .grid-row .item .content {
    border: 0; }
  .included .grid-row .item {
    margin: 0 auto;
    grid-template-columns: 1fr;
    max-width: 550px; } }

@media (max-width: 700px) {
  .included .cta-container .btn-prime {
    display: block; }
  .included .cta-container .btn-prime.secondary {
    margin-top: 10px; } }

/******************************
learning
******************************/
.learning {
  text-align: center; }
  .learning .main-video .video-container {
    position: relative;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center; }
  .learning .main-video .img-container {
    position: relative; }
    .learning .main-video .img-container::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #000;
      opacity: 0; }
  .learning .main-video .play-btn {
    position: absolute;
    max-width: 80px;
    z-index: 1; }
  .learning .text {
    margin: 40px 0; }

@media (max-width: 700px) {
  .learning .cta-container .btn-prime {
    display: block; }
    .learning .cta-container .btn-prime.secondary {
      margin-top: 10px; } }

@media (max-width: 600px) {
  .learning .main-video .play-btn {
    max-width: 50px; } }

/**********************
How It Works
*************************/
.how {
  position: relative;
  text-align: center; }
  .how .section-header {
    margin-bottom: 80px; }
    .how .section-header .sub-heading {
      color: #000;
      margin-bottom: 30px; }
  .how .icons {
    margin: 0 0 30px; }
    .how .icons .icon {
      max-width: 40%;
      margin: 0 auto 20px; }
      .how .icons .icon p {
        margin-bottom: 0; }
    .how .icons .icon-title {
      font-weight: 700;
      font-size: 22px;
      text-transform: uppercase;
      margin-bottom: 20px; }
      .how .icons .icon-title span {
        display: block; }
    .how .icons .icon-text {
      margin-bottom: 0; }
  .how .img-container {
    margin-bottom: 40px; }

@media only screen and (max-width: 991px) {
  .how .icon-container {
    max-width: 500px;
    margin: 0 auto; }
    .how .icon-container:not(:last-child) {
      margin-bottom: 55px; } }

@media only screen and (max-width: 767px) {
  .how .icon-container:not(:last-child) {
    margin-bottom: 30px; } }

@media only screen and (max-width: 600px) {
  .how .section-header {
    margin-bottom: 34px; } }

@media only screen and (max-width: 375px) {
  .how .icons .icon-title {
    font-size: 22px; } }

/**********************
About
*************************/
.about {
  background-color: #F1F3F5; }
  .about p {
    font-weight: 500; }
  .about .section-title {
    color: #000; }
  .about img {
    border-radius: 0;
    border: none; }

@media only screen and (max-width: 991px) {
  .about {
    text-align: center; }
    .about .flex-container {
      -webkit-box-orient: vertical;
      -webkit-box-direction: reverse;
      flex-direction: column-reverse; }
      .about .flex-container .section-title {
        font-size: 34px; }
    .about img {
      margin-bottom: 40px; } }

/**********************
reviews
**********************/
.reviews {
  background: #E74C3C;
  font-family: sofia-pro, sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  color: #fff; }
  .reviews .flex-container {
    margin-bottom: 60px; }
  .reviews .section-header {
    margin-bottom: 60px;
    text-align: center; }
  .reviews .slick-track {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center; }
  .reviews .slick-dotted.slick-slider {
    margin-bottom: 0; }
  .reviews .product-next,
  .reviews .product-prev {
    cursor: pointer; }
  .reviews .quote {
    font-size: 30px;
    line-height: 1.2; }
  .reviews .reviewer {
    font-size: 17px;
    margin-top: 30px;
    text-align: right;
    font-weight: 700; }
    .reviews .reviewer span {
      display: block; }

@media only screen and (max-width: 991px) {
  .reviews {
    text-align: center; }
    .reviews .right {
      -webkit-box-orient: vertical;
      -webkit-box-direction: reverse;
      flex-direction: column-reverse; }
    .reviews .reviewer {
      text-align: center; }
  .reviews .item img {
    margin: 0 auto 40px;
    max-width: 400px;
    width: 100%; } }

@media only screen and (max-width: 600px) {
  .reviews .item img {
    margin-bottom: 20px;
    max-width: 200px; }
  .reviews .quote {
    font-size: 22px; }
  .reviews .reviewer {
    font-size: 16px; } }

.cta {
  background-color: #F1F3F5;
  text-align: center;
  color: #000; }
  .cta .section-header {
    margin-bottom: 0; }
  .cta strong {
    color: #E74C3C;
    font-weight: 700; }
  .cta .price {
    color: #E74C3C;
    font-weight: 700; }
  .cta .cta-container {
    margin-top: 30px; }

/**********************
Footer
**********************/
footer {
  background-color: #222F3C;
  color: #fff;
  padding: 60px 0;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600; }
  footer .btn-prime {
    background-color: #E74C3C;
    color: #fff; }
  footer .footer-content {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between; }
    footer .footer-content.footer-top {
      margin-bottom: 20px; }
      footer .footer-content.footer-top .img-container {
        max-width: 250px; }
  footer .social {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    margin: 15px 0; }
    footer .social svg {
      margin: 0 10px;
      font-size: 20px;
      color: #fff; }

@media only screen and (max-width: 700px) {
  footer {
    text-align: center; }
  footer .footer-content {
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column; }
  footer .img-container {
    margin-bottom: 30px; } }

/******************************
Site Specific
******************************/
.nav-item {
  padding: .5rem 1rem;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  font-weight: 700; }
  .nav-item:hover {
    color: #222F3C !important; }

header {
  background-color: transparent;
  box-shadow: none;
  z-index: 1;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }

.header-active {
  background-color: white;
  padding: 10px 0 !important; }
  .header-active .buy-now {
    background-color: #E74C3C;
    border-color: #E74C3C; }

.platforms .logos-container {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  font-size: 50px;
  line-height: 1;
  margin-bottom: 12px; }
  .platforms .logos-container i {
    margin-right: 8px; }
    .platforms .logos-container i:last-child {
      margin-right: 0; }

@media (max-width: 768px) {
  .section-title {
    font-size: 42px; } }

@media (max-width: 700px) {
  .section-title {
    font-size: 32px; } }

@media (max-width: 450px) {
  .section-title {
    font-size: 30px; }
  .sale-text {
    font-size: 18px; } }
